/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import gql from 'graphql-tag';
import {
  InventoryLocation,
  InventoryLocationBuilding,
  InventoryMeter,
  InventoryMeterApiKey,
  InventoryMeterPhoto,
  Manufacturer,
  MeterModel,
} from '@enmon/commons/lib/tools';

export const GET_INVENTORY_METER_DATA = gql`
  query data($id: ID!) {
    inventoryMeters(filters: { id: { eq: $id } }) {
      data {
        id
        attributes {
          accessibility
          backend_gateway_meter_id
          building_floor
          calibrated
          meter_mount_type
          remote_reading_owner
          remote_reading_serial
          submeter
          new_meter
          lat
          lng
          floorplan_lat
          floorplan_lng
          manufactured
          meter_type
          owner_type
          monitored_entity
          owner
          lorawan_rssi
          nbiot_rssi
          serial_number
          tenant

          inventory_location_building {
            data {
              id
              attributes {
                name
              }
            }
          }

          inventory_location {
            data {
              id
              attributes {
                name
                address
              }
            }
          }

          meter_model {
            data {
              id
              attributes {
                name
                avatar_url
                website
                folder_drive_url
                manufacturer {
                  data {
                    id
                    attributes {
                      name
                      avatar_url
                    }
                  }
                }
              }
            }
          }

          inventory_meter_api_key {
            data {
              id
              attributes {
                api_key
              }
            }
          }

          inventory_meter_photos(filters: { type: { in: ["detail", "context", "placementOverview"] } }) {
            data {
              id
              attributes {
                file_drive_id
              }
            }
          }
        }
      }
    }
  }
`;

export interface InventoryMeterVariables {
  id: string | number;
}

export type InventoryMeterData = Pick<
  InventoryMeter,
  | 'id'
  | 'accessibility'
  | 'building_floor'
  | 'calibrated'
  | 'submeter'
  | 'new_meter'
  | 'meter_mount_type'
  | 'remote_reading_owner'
  | 'remote_reading_serial'
  | 'lat'
  | 'lng'
  | 'floorplan_lat'
  | 'floorplan_lng'
  | 'manufactured'
  | 'meter_type'
  | 'owner_type'
  | 'monitored_entity'
  | 'note'
  | 'owner'
  | 'lorawan_rssi'
  | 'nbiot_rssi'
  | 'serial_number'
  | 'tenant'
> & {
  inventory_location_building: Pick<InventoryLocationBuilding, 'id' | 'name'>;
  inventory_location: Pick<InventoryLocation, 'id' | 'name' | 'address'>;
  meter_model: Pick<MeterModel, 'id' | 'name' | 'avatar_url' | 'website' | 'folder_drive_url'> & {
    manufacturer: Pick<Manufacturer, 'id' | 'name' | 'avatar_url'>;
  };
  inventory_meter_api_key: Pick<InventoryMeterApiKey, 'id' | 'api_key'>;
  inventory_meter_photos: Array<{ file_drive_id: InventoryMeterPhoto['file_drive_id']; id: number }>;
};

export interface InventoryLocationMeterData {
  inventoryMeters: { data: InventoryMeterData[] };
}
